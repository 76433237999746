import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";
import EmailFill from "../icon/EmailFill";

class ContacUs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <div className="title-head">Contact</div>
          <div className="contact-content">
            <div className="title">
              <div className="text">Get In touch with us</div>
            </div>
            <div className="icon">
              <EmailFill size={70} />
            </div>
            {/* email */}
            <p>sell@mtqlo.shop</p>
            <p>
              If you have any questions please do feel free to get in touch via
              our email above. We try to reply to all emails in within a 48 hour
              service from Monday to Friday. If you don’t get a reply within 48
              hours please do not worry as we may be busy but rest assured we
              reply to every enquiry.{" "}
            </p>
            <p>Thanks for shopping with us.</p>
            <p>
              If you have any problems or queries relating to this order please
              email sell@mtqlo.shop and attach a picture of the delivery
              form and an image of the query you have with your order. Please
              note all queries need to be emailed to Sweetzy within 7 days of
              your delivery due to the products being perishable.
            </p>
            <p>Thank You</p>
            <p>The Sweetzy Team</p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContacUs;
